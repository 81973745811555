import { selectorFamily } from 'recoil'

import { getAnnouncement } from '@ello/business/services'
import type { Announcement } from '@ello/interfaces/announcements'

import { authState } from '../authentication'
import { announcementCollectionState } from './announcement-state'
import { OutletLabel } from '@ello/interfaces'

const MOCK: Partial<Announcement> = {
  id: 'mock',
  subject: 'Mock Announcement',
  body: 'This is a mock announcement',
  targets: [OutletLabel.Email, OutletLabel.SMS, OutletLabel.Telegram],
}

export const announcementSelector = selectorFamily({
  key: 'AnnouncementSelector',
  get: (id: string | undefined) => ({ get }): Promise<Announcement | null> => {
    // Can't get announcement with no identifier
    if (!id) return Promise.resolve(null)

    const allAnnouncements = get(announcementCollectionState)
    const cachedAnnouncement = allAnnouncements?.find(
      ({ id: storedId }) => storedId === id
    )

    if (cachedAnnouncement) {
      return Promise.resolve(cachedAnnouncement)
    }

    const credentials = get(authState)
    const token = credentials?.accessToken

    if (!token) return Promise.resolve({...MOCK, id} || null)

    return getAnnouncement(id, token)
  }
})
