/* eslint-disable max-len */
import { captureException } from '@sentry/browser'
import { Amplify } from 'aws-amplify'
import { getCurrentUser } from 'aws-amplify/auth'
import { atom, AtomEffect } from 'recoil'

type AuthenticationState = {
  memberId: string
  username: string
  accessToken: string
}

Amplify.configure({
  Auth: {
    Cognito: {
      // Amazon Cognito User Pool ID
      userPoolId: 'ap-southeast-2_9ePHTPJqu',
      // Amazon Cognito Web Client ID
      userPoolClientId: 'e6crer6vhpmtt8172ob3hgr95',

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //   domain: '.localhost:8020',
      //   // OPTIONAL - Cookie path
      //   path: '/',
      //   // OPTIONAL - Cookie expiration in days
      //   expires: 365,
      //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //   sameSite: 'lax',
      //   // OPTIONAL - Cookie secure flag
      //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //   secure: true
      // },

      // OPTIONAL - customized storage object
      // storage: MyStorage,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      clientMetadata: { myCustomKey: 'myCustomValue' }
    }
  }
})

const initializeState: AtomEffect<AuthenticationState | null> = ({ setSelf }) =>
  setSelf(
    getCurrentUser()
      .then((credentials) => {
        console.log(`The username: ${credentials.username}`)
        console.log(`The userId: ${credentials.userId}`)
        console.log(`The signInDetails: ${credentials.signInDetails}`)

        return {
          memberId: credentials.userId,
          username: credentials.username,
          authToken: credentials.username
        }
      })
      .catch((error) => {
        captureException(error)

        return null
      })
  )

export const authState = atom<AuthenticationState | null>({
  key: 'authState',
  default: null,
  effects_UNSTABLE: []
})
