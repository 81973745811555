import { Button, Divider, Typography } from '@sigatoka/elements'
import { AccountCell } from '../account-cell'
import { useTranslation } from 'react-i18next'

import styles from './ExistingSessions.module.less'
import { useNavigate } from 'react-router'
import { Session, useSession } from '@ello/state/authentication/use-session'

interface ExistingSessionsProps extends Partial<Common.Props> {}

const platformLabelMap = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter',
  mobile: 'Mobile'
}

export const ExistingSessions = ({
  testID = 'sessions'
}: ExistingSessionsProps) => {
  const navigate = useNavigate()
  const { history: sessions, selectSession } = useSession()
  const { t } = useTranslation('variants')

  const handleCreateSession = () => {
    navigate('register')
  }

  const handleSessionSelect = (session: Session) => () => {
    selectSession(session.id)
    const redirectUrl = new URLSearchParams(location.search).get('redirect')
    navigate(redirectUrl ? decodeURIComponent(redirectUrl) : '/')
  }

  const handleForgetSession = (session: Session) => () => {
    console.log('Forget session:', session)
  }

  return (
    <div data-testid={testID} className={styles.frame}>
      <Typography
        testID={`${testID}.title`}
        variant={'lead'}
        text={`Ello again!`}
      />

      <Typography
        testID={`${testID}.description`}
        text={`Manage your account, devices, announcements, check notifications and more.`}
      />

      <div data-testid={`${testID}.sessions`} className={styles.collection}>
        {sessions.map((session) => (
          <AccountCell
            testID={`${testID}.session`}
            key={session.id}
            title={session.handle}
            subtitle={platformLabelMap[session.platform]}
            onClick={handleSessionSelect(session)}
            onAction={handleForgetSession(session)}
          />
        ))}
      </div>

      <Divider
        testID={`${testID}.divider`}
        text={t('SessionsDividerLabel', { defaultValue: `OR` })}
      />

      <Button
        testID={`${testID}.button`}
        variant={'secondary'}
        size={'large'}
        onClick={handleCreateSession}
        label={t('SessionsCreate', { defaultValue: `Add another account` })}
      />
    </div>
  )
}
