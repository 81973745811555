import axios from 'axios'

import { ServiceEndpoint } from './service-endpoint'

export const deleteAnnouncement = (
  announcementId: string,
  accessToken?: string | null
): Promise<boolean> => {
  if (announcementId && accessToken) {
    return axios
      .delete(ServiceEndpoint.servicePath(`message/${announcementId}`), {
        headers: {
          Authorization: accessToken
        }
      })
      .then((response) => !!response?.data?.success)
      .catch((error) => {
        console.error(error)

        return false
      })
  }

  return Promise.resolve(false)
}
