import { OutletType } from '@ello/interfaces'

export const getTargetText = (target: OutletType): string => {
  switch (target) {
    case OutletType.SMS:
      return 'SMS'

    case OutletType.Email:
      return 'Email'

    case OutletType.Messenger:
      return 'Messenger'

    case OutletType.Push:
      return 'Push Notification'

    case OutletType.Telegram:
      return 'Telegram'

    case OutletType.Webhook:
      return 'Webhook'

    default:
      return 'Unknown'
  }
}

export const getTargetKey = (target: OutletType): string | null => {
  switch (target) {
    case OutletType.SMS:
      return 'sms'

    case OutletType.Email:
      return 'email'

    case OutletType.Messenger:
      return 'messenger'

    case OutletType.Push:
      return 'push'

    case OutletType.Telegram:
      return 'telegram'

    case OutletType.Webhook:
      return 'webhook'

    default:
      return null
  }
}

export const getTargetIndex = (key: string): OutletType => {
  switch (key) {
    case 'sms':
      return OutletType.SMS

    case 'email':
      return OutletType.Email

    case 'messenger':
      return OutletType.Messenger

    case 'push':
      return OutletType.Push

    case 'telegram':
      return OutletType.Telegram

    case 'webhook':
      return OutletType.Webhook

    default:
      return OutletType.Unknown
  }
}
