import React, { useMemo } from 'react'
import { Typography } from '@sigatoka/elements'
import { useRecoilValueLoadable } from 'recoil'

import { AccountCell } from '@ello/components/account-cell'
import { AnnouncementCell } from '@ello/components/announcement-cell'
import { SpringSelect } from '@ello/components/spring-select'
import { useViewController } from '@ello/hooks/use-view-controller'

import { announcementsListSelector } from '@ello/state/announcements'

import styles from './welcome.module.less'

export const FeedScene = ({
  testID = 'feed'
}: Partial<Common.Props>): React.ReactElement => {
  useViewController({
    title: 'FeedScene',
    transparent: true
  })

  const { state, contents } = useRecoilValueLoadable(announcementsListSelector)

  const announcements = useMemo(() => {
    if (state !== 'hasValue') return []

    return contents || []
  }, [state, contents])

  return (
    <div data-testid={testID} className={styles.frame}>
      <Typography
        testID={`${testID}.message`}
        variant={'lead'}
        text={`Ello again!`}
      />

      <div data-testid={`${testID}.collection`} className={styles.collection}>
        {announcements.map((announcement) => (
          <AnnouncementCell
            key={announcement.id}
            testID={`${testID}.announcement`}
            id={announcement.id}
            title={announcement.subject}
            metrics={[
              { label: 'Views', value: announcement.viewCount },
              { label: 'Subscribers', value: announcement.subscriberCount },
              { label: 'Broadcasts', value: announcement.broadcastCount }
            ]}
          />
        ))}
      </div>

      <div className={styles.button}>
        <Typography testID={`${testID}.label`} text={`Create announcement`} />
        <SpringSelect testID={`${testID}.selector`} onChange={() => null} />
      </div>
    </div>
  )
}
