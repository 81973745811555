import styles from './Header.module.less'

interface HeaderProps extends Common.Props {}

export const Header = ({
  testID,
  children
}: React.PropsWithChildren<HeaderProps>) => (
  <div data-testid={`${testID}.header`} className={styles.frame}>
    {children}
  </div>
)
