import { createContext, useContext } from 'react'

type ApplicationConfig = {
  ASSET_BASE_URL: string
}

type PartialConfig = Partial<ApplicationConfig>

export const ConfigContext = createContext<PartialConfig>({})

export const useConfig = (): {
  config: PartialConfig
  getIconUrl: (fileName: string) => string
  getAssetUrl: (fileName: string) => string
} => {
  const config = useContext(ConfigContext)

  const getIconUrl = (fileName: string): string =>
    `${config.ASSET_BASE_URL}/icons/${fileName}.png`

  const getAssetUrl = (fileName: string): string =>
    `${config.ASSET_BASE_URL}/json/${fileName}`

  return {
    config,
    getIconUrl,
    getAssetUrl
  }
}
