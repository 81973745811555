import { Pill, Typography } from '@sigatoka/elements'

import styles from './SavedPayment.module.less'

export type PaymentMethod = 'card' | 'bank' | 'paypal' | 'crypto' | 'apple'

interface SavedPaymentProps extends Common.Props {
  method?: PaymentMethod
  label: string
  detail: string
  preferred?: boolean
}

export const SavedPayment = ({
  testID,
  method = 'card',
  label,
  detail,
  preferred
}: SavedPaymentProps): React.ReactElement => (
  <div className={styles.frame}>
    <div className={styles.logo} />
    <Typography testID={`${testID}.label`} text={label} />
    <Typography
      testID={`${testID}.detail`}
      weight={method === 'apple' ? 'bold' : 'inherit'}
      text={detail}
    />
    {preferred && (
      <Pill
        testID={`${testID}.highlight`}
        className={styles.pill}
        label={'preferred'}
      />
    )}
    <div className={styles.spacer} />
    <div className={styles.action} />
  </div>
)
