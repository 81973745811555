import { atom } from 'recoil'

import { AnnouncementVariant } from '@ello/interfaces'

export const variantState = atom<Partial<AnnouncementVariant>>({
  key: 'VariantState',
  default: {
    targets: [],
    dateCreated: Date.now(),
    dateModified: null
  }
})
