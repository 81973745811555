/**
 * This class should really map the operation identifier
 * with the corresponding API path and/or action. Then
 * it should extend the Root endpoint class config to
 * construct a full path
 */
export class ServiceEndpoint {
  private static get subdomain(): string {
    return 'dev'
  }

  private static get domainName(): string {
    const domain = ['api', ServiceEndpoint.subdomain, 'ello.social'].join('.')

    return `https://${domain}`
  }

  public static servicePath(path?: string): string {
    return [ServiceEndpoint.domainName, path].join('/')
  }
}
