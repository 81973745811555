import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Icon, Spinner, Typography } from '@sigatoka/elements'
import { Card } from '@ello/components/card'
import lottie from 'lottie-web-light'

import { useConfig } from '@ello/hooks'

import styles from './AnnouncementCell.module.less'

interface AnnouncementCellProps extends Common.Props {
  id: string
  title: string
  metrics?: { label: string; value: number }[]
  onClick?: () => void
}

const AnnouncementCellSkeleton = ({
  testID
}: Common.Props): React.ReactElement => (
  <Card testID={testID} loading padded rounded>
    <div className={styles.frame}>
      <div className={styles.content}>
        <Typography
          testID={`${testID}.label`}
          text={`Loading...`}
          variant={'subtitle'}
          weight={'bold'}
          loading
        />
        <div data-testid={`${testID}.details`} className={styles.details}>
          <Typography
            testID={`${testID}.label`}
            text={`Loading...`}
            variant={'label'}
            weight={'bold'}
            loading
          />
        </div>
      </div>
      <Spinner
        testID={`${testID}.spinner`}
        color={'var(--base-700)'}
        weight={4}
      />
    </div>
  </Card>
)

const AnnouncementCell = ({
  testID = 'notification',
  id,
  title,
  metrics = []
}: AnnouncementCellProps): React.ReactElement => {
  const { getAssetUrl } = useConfig()
  const iconRightRef = useRef<HTMLDivElement>(null)

  useEffect((): void | (() => void) => {
    if (iconRightRef.current) {
      const handle = lottie.loadAnimation({
        name: 'icon-right',
        path: getAssetUrl('chevron.json'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        container: iconRightRef.current
      })

      return (): void => handle.destroy('icon-right')
    }
  }, [iconRightRef.current])

  return (
    <NavLink to={`/announcement/${id}`} className={styles.unlink}>
      <div data-testid={testID} className={styles.frame}>
        <span className={styles.mainIcon}/>

        <div className={styles.content}>
          <Typography
            testID={`${testID}.subject`}
            variant={'title'}
            text={title}
          />
          <div data-testid={`${testID}.details`} className={styles.details}>
            {metrics.map((metric) => (
              <div
                key={metric.label}
                data-testid={`${testID}.metric`}
                className={styles.metric}
              >
                <Icon
                  testID={`${testID}.icon`}
                  src={'user'}
                  className={styles.icon}
                />
                <Typography
                  testID={`${testID}.label`}
                  variant={'label'}
                  text={`${metric.value} ${metric.label}`}
                />
              </div>
            ))}
          </div>
        </div>

        <div
          data-testid={`${testID}.chevron`}
          ref={iconRightRef}
          style={{ transform: 'rotate(-90deg)' }}
          className={styles.accessory}
        />
      </div>
    </NavLink>
  )
}

export { AnnouncementCell, AnnouncementCellSkeleton }
