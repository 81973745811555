import React from 'react'

import { TriggerType } from '@ello/interfaces/triggers'

import { Detail } from '../detail'
import { MessageDetailsProps } from './message-details.interface'
import styles from './message-details.module.less'

export const MessageDetails = ({
  testID,
  id,
  subscribers,
  targets = [],
  triggers = [],
  broadcasts,
  variants = [],
  loading,
  value = 0
}: MessageDetailsProps): React.ReactElement => (
  <div data-testid={testID} className={styles.frame}>
    <Detail
      testID={`${testID}.subscribers`}
      icon={'users'}
      label={`${subscribers ? subscribers : 0} subscribers`}
      loading={loading}
    />
    <Detail
      testID={`${testID}.variants`}
      to={'variants'}
      icon={'edit'}
      label={`${variants.length} Message variations`}
      loading={loading}
    />
    <Detail
      testID={`${testID}.schedules`}
      to={'triggers'}
      icon={'schedule'}
      label={`${triggers.includes(TriggerType.Schedule) ? 1 : 'No'} Schedule`}
      muted={!triggers.includes(TriggerType.Schedule)}
      loading={loading}
    />

    <Detail
      testID={`${testID}.locations`}
      to={'triggers'}
      icon={'location'}
      label={`${triggers.includes(TriggerType.Location) ? 1 : 'No'} Location`}
      muted={!triggers.includes(TriggerType.Location)}
      loading={loading}
    />

    <Detail
      testID={`${testID}.events`}
      to={'triggers'}
      icon={'event'}
      label={`${triggers.includes(TriggerType.Event) ? 1 : 'No'} Events`}
      muted={!triggers.includes(TriggerType.Event)}
      loading={loading}
    />

    {id ? (
      <Detail testID={`${testID}.share`} to={`/${id}`} label={`Share`} />
    ) : null}

    <Detail
      testID={`${testID}.broadcasts`}
      icon={'broadcast'}
      label={`${broadcasts || 0} Broadcasts`}
      muted={!broadcasts || broadcasts <= 0}
      loading={loading}
    />
  </div>
)
