import React, { useMemo } from 'react'
import { Card, Link, Typography } from '@sigatoka/elements'

import { getTargetText } from '@ello/business'

import { VariantCellProps } from './variant-cell.interface'
import styles from './variant-cell.module.less'

export const VariantCell = ({
  testID,
  variant,
  variables = { name: 'Mitch' }
}: VariantCellProps): React.ReactElement => {
  const segments = useMemo(
    () =>
      variant.body.split(/({{[\w\d:]+}})/gi).map((str) => ({
        type: /{{.+}}/gi.test(str) ? 'tag' : 'text',
        label: str,
        value: /{{.+}}/gi.test(str) ? variables[str] : null
      })),
    [variant.body, variables]
  )

  return (
    <div data-testid={testID} className={styles.frame}>
      <div data-testid={`${testID}.header`} className={styles.header}>
        <div>
          <Typography
            testID={`${testID}.targets`}
            variant={'title'}
            text={variant.targets.join(', ') + ' - '}
            inline
          />
          <Typography
            testID={`${testID}.subject`}
            variant={'body'}
            weight={'heavy'}
            text={variant.subject}
            inline
          />
        </div>
        <Link testID={`${testID}.delete`} to={'/variant/delete'}>
          <img
            src={'https://cdn.onlinewebfonts.com/svg/img_364596.png'}
            alt={'cross'}
            height={8}
          />
        </Link>
      </div>

      <div>
        {segments.map(({ type, label, value }) => {
          if (type === 'tag') {
            return (
              // <Tooltip
              //   testID={`${testID}.tip`}
              //   label={'edit | save'}
              //   position={'top'}
              // >
              <Typography
                testID={`${testID}.variable`}
                text={label}
                variant={'small'}
                className={styles.variable}
                inline
              />
              // </Tooltip>
            )
          }

          return (
            <Typography
              testID={`${testID}.body`}
              key={type + label}
              text={label}
              variables={variables}
              inline
            />
          )
        }, [])}
      </div>
    </div>
  )
}
