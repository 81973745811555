import axios from 'axios'

import { Announcement } from '@ello/interfaces'

import { ServiceEndpoint } from './service-endpoint'

type CreateAnnouncementInput = Partial<Announcement> &
  Pick<Announcement, 'subject' | 'body' | 'targets' | 'triggers'>

export const createAnnouncement = (
  { subject, body, targets, triggers }: CreateAnnouncementInput,
  accessToken: string
): Promise<Announcement | null> => {
  if (subject && body) {
    return axios
      .post(
        ServiceEndpoint.servicePath('announcement'),
        {
          subject,
          body,
          targets,
          triggers
        },
        {
          headers: {
            Authorization: accessToken
          }
        }
      )
      .then((response) => response?.data?.message || null)
      .catch((error) => {
        console.error(error)

        return null
      })
  }

  return Promise.resolve(null)
}
