export enum TriggerLabel {
  Manual = 'manual',
  Schedule = 'scheduled',
  Event = 'event',
  Location = 'location',
  WebHook = 'webhook'
}

export enum TriggerType {
  Manual = 0,
  Schedule,
  Event,
  Location
}
