import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'


import { Button, Divider, Input, Typography } from '@sigatoka/elements'

import { NavLink } from 'react-router-dom'

import styles from './RegisterForm.module.less'

export const RegisterForm = ({
  testID = 'register'
}: Partial<Common.Props>) => {
  const { t } = useTranslation()

  const handleInputChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      console.log(target.value)
    },
    []
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      console.log('Submit')
    },
    []
  )

  return (
    <form className={styles.frame} onSubmit={handleSubmit}>
      <Typography
        testID={`${testID}.title`}
        variant={'heading'}
        text={t('RegisterAccountTitle', `Let's get started!`)}
      />
      <Typography
        testID={`${testID}.description`}
        text={t(
          'RegisterAccountDescription',
          `Choose your preferred authentication method to get started.`
        )}
      />

      <Input
        testID={`${testID}.input`}
        label={t('RegisterAccountInputLabel', `Email or mobile number`)}
        placeholder={t('RegisterAccountInputPlaceholder', `example@email.com`)}
        onChange={handleInputChange}
      />

      <Divider
        testID={`${testID}.divider`}
        text={t('RegisterAccountDividerLabel', `OR`)}
      />

      <div data-testid={`${testID}.socials`} className={styles.socials}>
        {[
          { key: 'facebook', label: 'Facebook' },
          { key: 'instagram', label: 'Instagram' },
          { key: 'twitter', label: 'Twitter' }
        ].map((social) => (
          <NavLink key={social.key} className={styles.social} to={social.key}>
            <span className={styles.icon} />
          </NavLink>
        ))}
      </div>

      <Button
        testID={`${testID}.submit`}
        className={styles.button}
        size={'large'}
        label={t('RegisterAccountSubmitLabel', `Continue`)}
        rounded
      />
    </form>
  )
}
