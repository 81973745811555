/* eslint-disable max-len */
import { Switch, Typography } from '@sigatoka/elements'

import styles from './Preferences.module.less'
import { useTheme } from '@ello/state/preferences'

export const PreferencesScreen = ({
  testID = 'preferences'
}: Partial<Common.Props>): React.ReactElement => {
  const { mode, toggleTheme } = useTheme()

  return (
    <div data-testid={testID} className={styles.frame}>
      <div data-testid={`${testID}.header`}>
        <Typography
          testID={`${testID}.heading`}
          variant={`heading`}
          text={`Preferences`}
        />
        <Typography
          testID={`${testID}.description`}
          text={`Choose how you'd like to hear from us. We'll send you notifications about your account, billing, and important updates to our services.`}
        />
      </div>

      <div data-testid={`${testID}.section`} className={styles.section}>
        <Typography
          testID={`${testID}.label`}
          text={`Where you're logged in`}
        />
        <Switch testID={`${testID}.switch`} />
      </div>

      <div data-testid={`${testID}.section`} className={styles.section}>
        <Typography testID={`${testID}.label`} text={`Dark mode`} />
        <Switch
          testID={`${testID}.switch`}
          name={'theme-mode'}
          checked={mode === 'dark'}
          onChange={toggleTheme}
        />
      </div>
    </div>
  )
}
