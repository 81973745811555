import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Spinner, Typography } from '@sigatoka/elements'
import { Card } from '@ello/components/card'
import classnames from 'classnames'
import lottie, { AnimationItem } from 'lottie-web-light'

import { useConfig } from '@ello/hooks'

import { DetailCellProps } from './detail-cell.interface'
import styles from './detail-cell.module.less'

const ICON_NAME = 'icon'
const ACCESSORY_NAME = 'accessory'

const DetailCellSkeleton = ({ testID }: Common.Props): React.ReactElement => (
  <Card testID={testID} className={styles.frame} loading padded rounded>
    <Spinner testID={`${testID}.spinner`} color={'var(--size-400)'} />
    <Typography
      testID={`${testID}.label`}
      text={`Loading...`}
      weight={'bold'}
      color={'inherit'}
      loading
    />
  </Card>
)

const DetailCell = ({
  testID,
  to,
  label
}: DetailCellProps): React.ReactElement => {
  const { getAssetUrl } = useConfig()
  const iconRef = useRef<HTMLDivElement>(null)
  const iconAnimationRef = useRef<AnimationItem>()
  const accessoryRef = useRef<HTMLDivElement>(null)
  const accessoryAnimationRef = useRef<AnimationItem>()

  useEffect(() => {
    if (!accessoryAnimationRef.current && accessoryRef.current) {
      accessoryAnimationRef.current = lottie.loadAnimation({
        name: ACCESSORY_NAME,
        path: getAssetUrl('arrow.json'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        container: accessoryRef.current
      })
    }

    if (!iconAnimationRef.current && iconRef.current) {
      iconAnimationRef.current = lottie.loadAnimation({
        name: ACCESSORY_NAME,
        path: getAssetUrl('star.json'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        container: iconRef.current
      })
    }

    return (): void => {
      accessoryAnimationRef.current?.destroy(ACCESSORY_NAME)
      iconAnimationRef.current?.destroy(ICON_NAME)
    }
  }, [])

  if (to) {
    return (
      <Card testID={testID} padded rounded>
        <NavLink to={to} className={classnames(styles.frame, styles.link)}>
          <div
            ref={iconRef}
            data-testid={`${testID}.icon`}
            className={styles.accessory}
          />

          <Typography
            testID={`${testID}.label`}
            text={label}
            weight={'bold'}
            color={'inherit'}
          />
        </NavLink>
      </Card>
    )
  }

  return (
    <Card testID={testID} className={styles.frame} padded rounded>
      <div
        ref={iconRef}
        data-testid={`${testID}.icon`}
        className={styles.accessory}
      />

      <Typography
        testID={`${testID}.label`}
        text={label}
        weight={'bold'}
        color={'inherit'}
      />
    </Card>
  )
}

export { DetailCell, DetailCellSkeleton }
