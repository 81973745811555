import { atom, AtomEffect, selector } from 'recoil'
import { v4 as generateRandom } from 'uuid'

import { allAnnouncements } from '@ello/business/services/all-announcements'
import type { Announcement } from '@ello/interfaces/announcements'

import { authState } from '../authentication'

const updateEvent: AtomEffect<Announcement> = ({ node, trigger, setSelf }) => {
  console.log('Here')
  if (trigger === 'set') {
    console.log(`Event ${node.key} has been updated`, node)
  }
}

const mockAnnouncements: Announcement[] = [
  {
    id: generateRandom(),
    subject: 'Welcome to Ello!',
    viewCount: 100,
    subscriberCount: 24,
    broadcastCount: 3
  } as Announcement,
  {
    id: generateRandom(),
    subject: 'Hello world!',
    viewCount: 64,
    subscriberCount: 12,
    broadcastCount: 5
  } as Announcement
]

export const announcementState = atom<Announcement>({
  key: 'AnnouncementState',
  default: {
    id: generateRandom(),
    owner: '',
    subject: '',
    body: null,
    limit: 0,
    subscribers: 0,
    invocations: 0,
    value: 0,
    priority: null,
    targets: [],
    triggers: [],
    variants: [],
    dateCreated: Date.now(),
    dateModified: null,
    dateInvoked: null,
    active: false,
    flagged: false,
    viewCount: 0,
    subscriberCount: 0,
    broadcastCount: 0
  },
  effects_UNSTABLE: [updateEvent]
})

export const announcementCollectionState = atom<Announcement[] | null>({
  key: 'AnnouncementCollectionState',
  default: mockAnnouncements
})

/**
 * Manages a single announcement selector, providing
 * data synchronization with the server and
 * periodic updates of details when changes
 * occur.
 */
export const announcementsListSelector = selector<Announcement[] | null>({
  key: 'AnnouncementsListSelector',
  get: async ({ get }) => {
    const announcements = get(announcementCollectionState)

    if (!announcements) {
      const credentials = get(authState)

      const items = await allAnnouncements(credentials?.accessToken, {
        limit: 10
      })

      return items
    }

    return announcements
  }
})
