import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSession } from '@ello/state'

interface RouteGuardProps {
  children: JSX.Element
}

export const RouteGuard: React.FC<RouteGuardProps> = ({ children }) => {
  const { session } = useSession()
  const location = useLocation()

  if (!session) {
    // Redirecting to login, you can also pass the current location to return after logging in
    return <Navigate to={`/auth?redirect=${encodeURIComponent(location.pathname + location.search)}`} replace />
  }

  return children
}
