import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button, Overlay, Typography } from '@sigatoka/elements'
import { useRecoilValue } from 'recoil'
import useSwr from 'swr'

import { getAnnouncement } from '@ello/business/services'
import { VariantCell } from '@ello/components/variant-cell'
import { VariantForm } from '@ello/components/variant-form'
import { useViewController } from '@ello/hooks/use-view-controller'
import type { AnnouncementVariant } from '@ello/interfaces/announcements'
import { authState } from '@ello/state/authentication'
import styles from './VariantsScene.module.less'
import { SpringSelect } from '@ello/components/spring-select'

export type VariantsSceneProps = Partial<Common.Props> & {
  variants?: AnnouncementVariant[]
}

const VariantsScene = ({
  testID = 'message-creator',
  variants: initialVariants = []
}: VariantsSceneProps): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false)
  const [variants, setVariants] = useState(initialVariants)
  const { t } = useTranslation('variants')
  const [hasChanges, setHasChanges] = useState(false)
  const credentials = useRecoilValue(authState)
  const { setState, transparent } = useViewController()
  const { id } = useParams()

  const { data } = useSwr([id, credentials?.accessToken], getAnnouncement, {
    errorRetryCount: 3
  })

  useEffect(() => {
    setState({ title: 'Variants', transparent })
    if (data) {
      setVariants(data.variants)
    }
  }, [data])

  const toggleEditing = useCallback(
    () => setIsEditing((state) => !state),
    [isEditing]
  )

  const createVariant = useCallback((variant: AnnouncementVariant) => {
    console.log(variants, variant)
    setVariants((prev) => [...prev, variant])

    setIsEditing(false)
  }, [])

  return (
    <div data-testid={testID} className={styles.frame}>
      <Typography
        testID={`${testID}.title`}
        variant={'lead'}
        text={t('ComposeVariantTitle', {
          defaultValue: `Message Variations`
        })}
      />

      <Typography
        testID={`${testID}.description`}
        text={t('ComposeVariantDescription', {
          defaultValue: `Configure how you'd like your message to appear on each target device`
        })}
      />

      {variants.map((variant) => (
        <VariantCell
          testID={`${testID}.variant`}
          key={variant.id}
          variant={variant}
        />
      ))}

      {isEditing && (
        <Overlay
          testID={`${testID}.overlay`}
          onClick={() => setIsEditing(false)}
        >
          <VariantForm testID={`${testID}.update`} onSubmit={createVariant} />
        </Overlay>
      )}

      <Button
        testID={`${testID}.new`}
        icon={'add'}
        label={t('CreateButtonLabel', { defaultValue: `Add a new variant` })}
        onClick={toggleEditing}
      />

      <div className={styles.button}>
        <Typography
          testID={`${testID}.label`}
          text={t('CreateButtonLabel', { defaultValue: `Add a new variant` })}
        />
        <SpringSelect testID={`${testID}.selector`} onChange={() => null} />
      </div>
    </div>
  )
}

export { VariantsScene }
