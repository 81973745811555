import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Checkbox, Spinner, Typography } from '@sigatoka/elements'
import { Card } from '@ello/components/card'
import clsx from 'classnames'
import lottie, { AnimationItem } from 'lottie-web-light'

import { useConfig } from '@ello/hooks/use-config'

import type { OptionCellProps } from './option-cell.interface'
import styles from './option-cell.module.less'

const ICON_NAME = 'icon'
const ACCESSORY_NAME = 'accessory'

const OptionCellSkeleton = ({ testID }: Common.Props): React.ReactElement => (
  <Card testID={testID} className={styles.frame} loading rounded>
    <Spinner testID={`${testID}.loading`} />

    <Typography
      testID={`${testID}.label`}
      text={`Loading...`}
      variant={'label'}
      weight={'bold'}
      color={'inherit'}
    />

    <Checkbox
      testID={`${testID}.select`}
      name={'skeleton'}
      className={styles.checkbox}
      disabled
    />
  </Card>
)

const OptionCell = ({
  testID,
  name,
  label,
  caption,
  selected: initialSelected,
  onSelected
}: OptionCellProps): React.ReactElement => {
  const { getAssetUrl } = useConfig()
  const iconRef = useRef<HTMLDivElement>(null)
  const iconAnimationRef = useRef<AnimationItem>()
  const accessoryRef = useRef<HTMLDivElement>(null)
  const accessoryAnimationRef = useRef<AnimationItem>()
  const [selected, setSelected] = useState(initialSelected ?? false)

  useEffect(() => {
    if (!accessoryAnimationRef.current && accessoryRef.current) {
      accessoryAnimationRef.current = lottie.loadAnimation({
        name: ACCESSORY_NAME,
        path: getAssetUrl('arrow.json'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        container: accessoryRef.current
      })
    }

    if (!iconAnimationRef.current && iconRef.current) {
      iconAnimationRef.current = lottie.loadAnimation({
        name: ACCESSORY_NAME,
        path: getAssetUrl('star.json'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        container: iconRef.current
      })
    }

    return (): void => {
      accessoryAnimationRef.current?.destroy(ACCESSORY_NAME)
      iconAnimationRef.current?.destroy(ICON_NAME)
    }
  }, [])

  const handleChange = useCallback(
    (value: boolean) => {
      if (onSelected) {
        onSelected(name, value)
      }

      setSelected(value)
    },
    [name, onSelected, setSelected]
  )

  const handleClick = useCallback(() => {
    if (onSelected) {
      onSelected(name, !selected)
    }

    setSelected((prevSelected) => !prevSelected)
  }, [name, selected, onSelected, setSelected])

  useEffect(() => {
    initialSelected && setSelected(initialSelected)
  }, [initialSelected, setSelected])

  return (
    <div
      data-testid={testID}
      className={clsx(styles.frame, selected && styles.selected)}
    >
      <div
        data-testid={`${testID}.cell`}
        role={'radio'}
        tabIndex={0}
        className={clsx(styles.cell, selected && styles.selected)}
        onKeyPress={handleClick}
        onClick={handleClick}
        aria-checked={selected}
      >
        {/* <div
        data-testid={testID}
        role={'radio'}
        tabIndex={0}
        className={clsx(styles.frame, selected && styles.selected)}
        onKeyPress={handleClick}
        onClick={handleClick}
        aria-checked={selected}
      > */}
        <span
          ref={iconRef}
          data-testid={`${testID}.icon`}
          className={styles.icon}
        />

        <Typography testID={`${testID}.label`} text={label} color={'inherit'} />

        <span data-testid={`${testID}.checkbox`} className={styles.checkbox} />
      </div>
      <Typography
        testID={`${testID}.caption`}
        component={'div'}
        variant={'caption'}
        className={styles.caption}
        align={'center'}
      >
        {caption}
      </Typography>
    </div>
  )
}

export { OptionCell, OptionCellSkeleton }
