import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Typography, Button } from '@sigatoka/elements'
import { useRecoilValueLoadable } from 'recoil'

import { getTargetKey, getTargetText } from '@ello/business/messages'
import { DetailCell } from '@ello/components/detail-cell'
import { useViewController } from '@ello/hooks/use-view-controller'
import { announcementSelector } from '@ello/state/announcements'

import styles from './InvitationScene.module.less'

const InvitationScene = ({
  testID = 'invitation',
  id: defaultId
}: Partial<Common.Props> & { id?: string }): React.ReactElement => {
  const { announcement: id = defaultId } = useParams()
  const { setState, transparent } = useViewController({ title: 'Subscribe' })
  const { state, contents } = useRecoilValueLoadable(announcementSelector(id))

  useEffect(() => {
    if (contents?.subject) {
      setState({ title: contents.subject, transparent })
    }
  }, [contents])

  const handleConfirm = () => {

  }

  return (
    <div data-testid={testID}>
      <Typography
        testID={`${testID}.subject`}
        variant={'lead'}
        text={contents?.subject}
      />
      <Typography testID={`${testID}.description`} text={contents?.body} />

      <Typography
        testID={`${testID}.prompt`}
        variant={'body'}
        text={`Choose how you'd like to be notified`}
      />

      <div data-testid={`${testID}.targets`} className={styles.targets}>
        {state === 'hasValue' &&
          contents?.targets?.map((target) => (
            <DetailCell
              key={target}
              testID={`${testID}.target`}
              to={getTargetKey(target) ?? ''}
              label={getTargetText(target)}
            />
          ))}
      </div>

      <Button
        testID={`${testID}.confirm`}
        onClick={handleConfirm}
        label={`Continue`}
        className={styles.button}
        size={'large'}
        variant={'primary'}
        rounded
      />
    </div>
  )
}

export { InvitationScene }
