import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon, Typography } from '@sigatoka/elements'
import { useRecoilValueLoadable } from 'recoil'

import {
  AnnouncementCell,
  AnnouncementCellSkeleton
} from '@ello/components/announcement-cell'
import { useViewController } from '@ello/hooks/use-view-controller'
import { announcementsListSelector } from '@ello/state/announcements'

import styles from './DiscoveryScene.module.less'

export const DiscoveryScene = ({
  testID = 'bulletin'
}: Partial<Common.Props>): React.ReactElement => {
  useViewController({
    title: 'DiscoveryScene',
    transparent: true
  })

  const { state, contents } = useRecoilValueLoadable(announcementsListSelector)

  return (
    <div data-testid={testID} className={styles.frame}>
      <div data-testid={`${testID}.feed`} className={styles.feed}>
        {state === 'loading'
          ? Array.from(Array(2)).map((_, idx) => (
              <AnnouncementCellSkeleton
                testID={`${testID}.skeleton`}
                key={`event-${idx}-skeleton`}
              />
            ))
          : null}

        {state === 'hasValue' &&
          contents &&
          contents.map((message) => (
            <AnnouncementCell
              key={message.id}
              testID={`${testID}.notification`}
              message={message}
            />
          ))}

        <NavLink
          data-testid={`${testID}.create`}
          to={'compose'}
          className={styles.create}
        >
          <Typography
            testID={`${testID}.label`}
            variant={'link'}
            color={'#fff'}
            text={`Create new alert`}
          />
          <Icon testID={`${testID}.icon`} src={'add'} />
        </NavLink>
      </div>
    </div>
  )
}
