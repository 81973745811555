export enum OutletLabel {
  Unknown = 'unknown',
  Telegram = 'telegram',
  SMS = 'sms',
  Email = 'email',
  Webhook = 'webhook',
  Messenger = 'messenger',
  Push = 'push'
}

export enum OutletType {
  Unknown = 0,
  Telegram,
  SMS,
  Email,
  Webhook,
  Messenger,
  Push
}

/**
 * Outlet should be a relationship between an
 * announcement and an entity device
 */
export type Outlet = {
  id: string
  owner: string
  type: OutletType
  label: string
  address: string
  value: string
  dateCreated: number
  dateModified: number | null
  active: boolean
  flagged: boolean
  approved: boolean
}
