import { Route as Page, Routes as Switch } from 'react-router'

import { ExistingSessions, LoginForm, RegisterForm } from '@ello/components'

import styles from './AuthenticationScene.module.less'

export const AuthenticationScene = ({
  testID = 'authentication'
}: Partial<Common.Props>): React.ReactElement => (
  <div data-testid={testID} className={styles.frame}>
    <Switch>
      <Page path={'login'} Component={LoginForm} />
      <Page path={'register'} Component={RegisterForm} />
      <Page path={'validate'} Component={LoginForm} />
      <Page path={'reset'} Component={LoginForm} />
      <Page index Component={ExistingSessions} />
    </Switch>
  </div>
)
