import type { Announcement, OutletLabel, TriggerLabel } from '@ello/interfaces'

export type CompositionState = Pick<
  Announcement,
  'subject' | 'body' | 'targets' | 'triggers'
>

export type CompositionAction =
  | { type: 'SET_INTEGRATIONS'; payload: OutletLabel[] }
  | { type: 'ADD_INTEGRATION'; payload: OutletLabel }
  | { type: 'SET_TRIGGERS'; payload: TriggerLabel[] }
  | { type: 'ADD_TRIGGER'; payload: TriggerLabel }
  | { type: 'SET_TITLE'; payload: string }
  | { type: 'SET_MESSAGE'; payload: string }

const defaultState: CompositionState = {
  subject: null,
  body: null,
  targets: [],
  triggers: []
}

export const messageReducer = (
  state: CompositionState = defaultState,
  action: CompositionAction
): CompositionState => {
  switch (action.type) {
    case 'SET_INTEGRATIONS':
      return { ...state, targets: action.payload }

    case 'ADD_INTEGRATION':
      return { ...state, targets: [...state.targets, action.payload] }

    case 'SET_TRIGGERS':
      return { ...state, triggers: action.payload }

    case 'ADD_TRIGGER':
      return { ...state, triggers: [...state.triggers, action.payload] }

    case 'SET_TITLE':
      return { ...state, subject: action.payload }

    case 'SET_MESSAGE':
      return { ...state, body: action.payload }

    default:
      return state
  }
}
