import { OutletDevice } from '@ello/components/device-outlet/DeviceOutlet'
import { Session } from '@ello/components/session-device/SessionDevice'

export const mockSessions: Session[] = [
  {
    id: '526ac8b1-2a96-435d-b584-2fb8a6da056d',
    details: {
      device: 'Apple Macintosh',
      browser: 'Chrome'
    },
    location: {
      city: 'San Francisco',
      region: 'California',
      country: 'United States'
    },
    lastActive: new Date()
  },
  {
    id: '0216d1ef-868c-40b4-89a4-7b23240c419e',
    details: {
      device: 'Apple iPhone',
      browser: 'Safari'
    },
    location: {
      city: 'Brisbane City',
      region: 'Queensland',
      country: 'Australia'
    },
    lastActive: new Date()
  }
]

export const mockOutlets: OutletDevice[] = [
  {
    id: 'ec72ea61-47c6-4b46-91ba-7d753879c32b',
    type: 'email',
    label: 'm***h@pierias.com',
    lastActive: new Date()
  },
  {
    id: 'cc6b3160-bd74-4879-a241-3182eaa70204',
    type: 'telegram',
    label: 'mitchpierias',
    lastActive: new Date()
  }
]
