import { useState } from 'react'
import { Button } from '@sigatoka/elements'
import clsx from 'classnames'

import styles from './SpringSelect.module.less'

type SelectorOption = {
  key: string
  icon: string
  alt: string
}

interface SpringSelectProps extends Common.Props {
  onChange: (option: SelectorOption) => void
}

const mockOptions: SelectorOption[] = [
  { key: 'a', icon: 'a', alt: 'location' },
  { key: 'b', icon: 'b', alt: 'schedule' },
  { key: 'c', icon: 'c', alt: 'place' },
  { key: 'd', icon: 'd', alt: 'stuff' }
]

export const SpringSelect = ({
  testID
}: SpringSelectProps): React.ReactElement => {
  const [expanded, setExpanded] = useState(false)

  const handleToggle = (): void => {
    setExpanded((prev) => !prev)
  }

  return (
    <span
      data-testid={testID}
      className={clsx(styles.frame, expanded && styles.expanded)}
    >
      {mockOptions.map((option: SelectorOption) => (
        <span
          key={option.key}
          data-testid={`${testID}.icon`}
          className={styles.icon}
        >
          {option.key.substring(0, 1)}
        </span>
      ))}
      <Button
        testID={`${testID}.toggle`}
        className={styles.button}
        onClick={handleToggle}
      >+</Button>
    </span>
  )
}
