import { NavLink } from 'react-router-dom'
import styles from './PageControl.module.less'
import { Icon } from '@sigatoka/elements';

type ControlOption = { key: string; path: string; icon: string }

interface PageControlProps extends Common.Props {
  options: ControlOption[]
}

export const PageControl = ({ testID, options }: PageControlProps) => {
  return (
    <span data-testid={testID} className={styles.frame}>
      {options.map((item) => (
        <NavLink key={item.key} data-testid={`${testID}.link`} to={item.path}>
          <Icon testID={`${testID}.icon`} icon={item.icon} />
        </NavLink>
      ))}
    </span>
  )
}
