import { Typography } from '@sigatoka/elements'

import styles from './DeviceOutlet.module.less'

export type OutletDevice = {
  id: string
  type: string
  label: string
  lastActive: Date
}

interface DeviceOutletProps extends Common.Props {
  type: string
  label: string
  lastActive: Date
}

export const DeviceOutlet = ({
  testID,
  type,
  label,
  lastActive
}: DeviceOutletProps): React.ReactElement => (
  <div className={styles.frame}>
    <div className={styles.icon} />
    <div className={styles.details}>
      <Typography testID={`${testID}.title`} variant={'title'} text={label} />
      <Typography
        testID={`${testID}.lastActive`}
        text={lastActive.toDateString()}
      />
    </div>
    <Typography testID={`${testID}.action`} text={`Forget ${type}`} underline />
  </div>
)
