import { atom, useRecoilState } from 'recoil'

import { localStorageEffect } from '../effects'

const ATOM_KEY = 'theme-mode'

enum ThemeMode {
  Light = 'light',
  Dark = 'dark'
}

const themeAtom = atom<ThemeMode>({
  key: ATOM_KEY,
  default: ThemeMode.Light,
  effects: [localStorageEffect(ATOM_KEY)]
})

export const useTheme = () => {
  const [mode, setMode] = useRecoilState(themeAtom)

  const toggleTheme = () =>
    setMode((currentMode) =>
      currentMode === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light
    )

  return {
    /**
     * Raw value of the currently selected theme.
     */
    mode,
    /**
     * Flips the theme between `light` and `dark` mode,
     * depending on what's currently selected.
     */
    toggleTheme
  }
}
