import {useEffect} from 'react'
import classnames from 'classnames'

import styles from './theme.module.less'
import { useTheme } from '@ello/state/preferences'

interface ThemeProviderProps {
  mode?: 'light' | 'dark'
}

export const ThemeProvider = ({
  mode: defaultMode = 'light',
  children
}: React.PropsWithChildren<ThemeProviderProps>): React.ReactNode => {
  const {mode} = useTheme()
  useEffect(() => {
    const rootElement = document.getElementsByTagName('body')

    if (rootElement.length > 0) {
      rootElement[0].className = classnames(styles.theme, styles[mode])
    }
  }, [mode])

  return children
}
