import { atom, useRecoilState } from 'recoil'

const ATOM_KEY = 'session-history'

enum Platform {
  Instagram = 'instagram',
  Mobile = 'mobile'
}

export type Session = {
  id: string
  platform: Platform
  handle: string
  name?: string
}

const MOCK_HISTORY: Session[] = [
  { id: '2a9516cc-af92-46ff-843f-260ee29ca91f', platform: Platform.Instagram, handle: 'mitchpierias', name: 'Mitch' },
  { id: 'aad8517d-230e-4f4d-a8a6-788db09de1c8', platform: Platform.Mobile, handle: '0412345678', name: 'Mitch' }
]

const sessionHistoryAtom = atom<Session[]>({
  key: ATOM_KEY,
  default: MOCK_HISTORY
})

export const useSessionHistory = () => {
  const [sessions, setSessions] = useRecoilState(sessionHistoryAtom)

  const addSession = () => {}

  return {
    /**
     * Raw value of the currently selected theme.
     */
    sessions,
    /**
     * Appends a new session to session storage
     */
    addSession
  }
}
