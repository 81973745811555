/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */

import React, { useReducer, useState } from 'react'
import { Button, Input, TabControl, Typography } from '@sigatoka/elements'

import { Header, VariantCell } from '@ello/components'
import { OptionCell } from '@ello/components/option-cell'
import { messageReducer } from '@ello/hooks/use-composer'
import { useEvent } from '@ello/hooks/use-event'

import styles from './Composer.module.less'
import { STEPS } from './steps'

export const ComposerScene = ({
  testID = 'composer'
}: Partial<Common.Props>): React.ReactElement | null => {
  const { loading, event, update, save } = useEvent()
  const [step, setStep] = useState(STEPS[0])

  const [state, dispatch] = useReducer(messageReducer, {
    ...event,
    targets: [],
    triggers: []
  })

  return (
    <div data-testid={testID} className={styles.frame}>
      <Header testID={`${testID}.header`}>
        <TabControl
          testID={`${testID}.controls`}
          value={step.key}
          variant={'primary'}
          options={STEPS.map(({ key, label }) => ({ label, value: key }))}
          onChange={(value): void => {
            const nextStep = STEPS.find(({ key }) => key === value)

            if (nextStep) setStep(nextStep)
          }}
          rounded
          dense
        />
      </Header>

      <div data-testid={`${testID}.options`} className={styles.options}>
        <Typography
          testID={`${testID}.heading`}
          variant={'lead'}
          text={step.label}
        />
        <Typography testID={`${testID}.prompt`} text={step.description} />
        {step?.options?.map(({ key, label, selected, note }) => (
          <OptionCell
            testID={`${testID}.cell`}
            key={key}
            name={key}
            label={label}
            caption={note}
            selected={
              state.triggers?.some((target) => target === key) ?? selected
            }
            onSelected={(): void =>
              dispatch({
                type: 'ADD_TRIGGER',
                payload: key
              })
            }
          />
        ))}
        {step.fields?.map(({ key, label, type }) => (
          <Input
            testID={`${testID}.input`}
            key={key}
            name={key}
            label={label}
            type={type}
            value={state[key]}
            onChange={(value): void =>
              dispatch({
                type: 'SET_FIELD',
                payload: { key, value }
              })
            }
          />
        ))}
        {step?.variants?.map((variant) => (
          <VariantCell
            testID={`${testID}.variant`}
            key={variant.key}
            variant={{ body: variant.body, targets: variant.targets }}
          />
        ))}
        <Button
          testID={`${testID}.next`}
          onClick={(): void => {
            const nextStep = STEPS.find(({ key }) => key === step.on?.NEXT)

            if (nextStep) setStep(nextStep)
          }}
          size={'large'}
          className={styles.button}
          loading={loading}
          label={`Continue`}
          rounded
        />
      </div>
    </div>
  )
}
