import { useState } from 'react'
import { Typography } from '@sigatoka/elements'
import clsx from 'classnames'

import {
  DeviceOutlet,
  OutletDevice
} from '@ello/components/device-outlet/DeviceOutlet'
import {
  Session,
  SessionDevice
} from '@ello/components/session-device/SessionDevice'

import { mockOutlets, mockSessions } from './data'
import styles from './Security.module.less'

export const SecurityScreen = ({
  testID = 'security'
}: Partial<Common.Props>): React.ReactElement => {
  const [sessions, setSessions] = useState<Session[]>(mockSessions)
  const [devices, setDevices] = useState<OutletDevice[]>(mockOutlets)

  return (
    <div data-testid={testID} className={styles.frame}>
      <div
        data-testid={`${testID}.section`}
        className={clsx(styles.section, styles.password)}
      >
        <Typography
          testID={`${testID}.heading`}
          variant={`heading`}
          text={`Password`}
        />
        <Typography testID={`${testID}.update`} text={`Update`} underline />
      </div>

      <div data-testid={`${testID}.section`} className={styles.section}>
        <Typography
          testID={`${testID}.heading`}
          variant={`heading`}
          text={`Where you're logged in`}
        />
        <Typography
          testID={`${testID}.description`}
          text={`Manage active sessions where you've opted to stay logged in.`}
        />
        <div data-testid={`${testID}.collection`} className={styles.collection}>
          {sessions.map((session) => (
            <SessionDevice
              key={session.id}
              testID={`${testID}.session`}
              details={session.details}
              location={session.location}
              lastActive={session.lastActive}
            />
          ))}
        </div>
      </div>

      <div data-testid={`${testID}.section`} className={styles.section}>
        <Typography
          testID={`${testID}.heading`}
          variant={`heading`}
          text={`Verified devices`}
        />
        <Typography
          testID={`${testID}.description`}
          text={`Manage the devices and outlets where we can reach you.`}
        />
        <div data-testid={`${testID}.collection`} className={styles.collection}>
          {devices.map((device) => (
            <DeviceOutlet
              key={device.id}
              testID={`${testID}.device`}
              type={device.type}
              label={device.label}
              lastActive={device.lastActive}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
