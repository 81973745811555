import { useMemo } from 'react'
import { Typography, Button } from '@sigatoka/elements'

import styles from './UsageScreen.module.less'
import { useAccount } from '@ello/state'

const formatPrice = (price: number): string => {
  return `$${price.toFixed(2)}`
}

const loadPlans = () => {
  return [
    {
      id: 'pay-as-you-go',
      name: 'Pay as you go'
    }
  ]
}

export const UsageScreen = ({
  testID = 'usage'
}: Partial<Common.Props>): React.ReactElement => {
  const { account } = useAccount()
  const availablePlans = loadPlans()
  const viewPlans = () => {}

  const currentPlan = useMemo(() => {
    const plan = availablePlans.find((plan) => plan.id === account.plan)
    return plan
  }, [availablePlans, account.plan])

  return (
    <div data-testid={testID} className={styles.frame}>
      <Typography
        testID={`${testID}.heading`}
        variant={`heading`}
        text={`Usage details`}
      />
      <div className={styles.card}>
        <div className={styles.section}>
          <Typography
            testID={`${testID}.title`}
            text={`Credit`}
            variant={'subtitle'}
          />
          <Typography
            testID={`${testID}.value`}
            text={formatPrice(account.usage.credit)}
            variant={'title'}
          />
          <Button
            testID={`${testID}.plans`}
            onClick={viewPlans}
            variant={'outline'}
            label={`View billing and plans`}
          />
        </div>
        <div className={styles.section}>
          <Typography
            testID={`${testID}.title`}
            text={`Current plan`}
            variant={'subtitle'}
          />
          {currentPlan && (
            <Typography
              testID={`${testID}.value`}
              text={currentPlan.name}
              variant={'title'}
            />
          )}
          <Typography
            testID={`${testID}.upgrade`}
            text={`Upgrade to receive better rates and benefits.`}
          />
        </div>
      </div>
    </div>
  )
}
