import { Route as Page, Routes as Switch, useParams } from 'react-router'

import { InvitationScene } from '../invitation'
import styles from './SubscribeScene.module.less'

export const SubscribeScene = ({
  testID = 'authentication'
}: Partial<Common.Props>): React.ReactElement => {
  const params = useParams<{ announcement: string }>()

  return (
    <div data-testid={testID} className={styles.frame}>
      <Switch>
        <Page path={':type'} Component={SubscribeScene} />
        <Page index Component={InvitationScene} />
      </Switch>
    </div>
  )
}
