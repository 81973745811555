import React, { useCallback, useState } from 'react'
import { Button, Input, Typography } from '@sigatoka/elements'
import { v4 as generateRandom } from 'uuid'

import { OutletType } from '@ello/interfaces'
import { MessageVariant } from '@ello/interfaces/messages/announcement.interface'

import { VariantFormProps } from './variant-form.interface'
import styles from './variant-form.module.less'
import { useTranslation } from 'react-i18next'
import { useDevToggles } from '@ello/state'

const mapOutletLabel: Record<OutletType, string> = {
  [OutletType.Unknown]: 'Unknown',
  [OutletType.Email]: 'Email',
  [OutletType.Telegram]: 'Telegram',
  [OutletType.Messenger]: 'Messenger',
  [OutletType.SMS]: 'SMS',
  [OutletType.Webhook]: 'Webhook',
  [OutletType.Push]: 'Push'
}

export const VariantForm = ({
  testID,
  onSubmit
}: VariantFormProps): React.ReactElement => {
  const { t } = useTranslation('variants')
  const [availableOutlets] = useState([OutletType.Email, OutletType.Telegram])
  const { isEnabled } = useDevToggles()
  const [variant, setVariant] = useState<MessageVariant>({
    id: generateRandom(),
    subject: '',
    body: '',
    targets: [],
    dateCreated: Date.now(),
    dateModified: null
  })

  const handleSubmit = useCallback(() => {
    if (onSubmit && variant) {
      onSubmit(variant)
    }
  }, [variant])

  const handleAssistant = useCallback(() => {
    console.log('Assistant is not implemented yet')
    console.log(
      'should open a chat in the context of the page and area they clicked on'
    )
  }, [])

  const handleTargetSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedOutlets =
        variant?.targets.reduce<OutletType[]>((merged, target) => {
          if (
            target === parseInt(event.target.value) &&
            !event.target.checked
          ) {
            return merged
          }

          merged.push(target)

          return merged
        }, []) ?? []

      setVariant((prev) => ({
        ...prev,
        targets: updatedOutlets
      }))
    },
    [variant]
  )

  return (
    <div data-testid={testID} className={styles.frame}>
      <Typography
        testID={`${testID}.title`}
        variant={'heading'}
        text={`Create new variant`}
      />
      <fieldset data-testid={`${testID}.outlets`} className={styles.outlets}>
        {availableOutlets.map((type) => (
          <label key={type} htmlFor={type.toString()} className={styles.pill}>
            <input
              type={'checkbox'}
              name={type.toString()}
              value={type}
              defaultChecked={variant?.targets.includes(type)}
              onChange={handleTargetSelection}
            />
            <Typography
              testID={`${testID}.outlet`}
              variant={'label'}
              text={mapOutletLabel[type]}
              inline
            />
          </label>
        ))}
      </fieldset>
      <Input
        testID={`${testID}.subject`}
        name={'subject'}
        label={'Subject'}
        defaultValue={variant?.subject}
        placeholder={'Catchy headline...'}
        onChange={({ target }) =>
          setVariant((prev) => ({ ...prev, subject: target.value }))
        }
      />
      <Input
        testID={`${testID}.body`}
        name={'body'}
        label={'Body'}
        defaultValue={variant?.body}
        placeholder={'Be creative with it!'}
        multiline
        onChange={({ target }) =>
          setVariant((prev) => ({ ...prev, body: target.value }))
        }
      />
      <span className={styles.actions}>
        {isEnabled('virtual-assistant') && (
          <Button
            testID={`${testID}.assistant`}
            variant={'muted'}
            onClick={handleAssistant}
            label={t('AssistantButtonLabel', `Chat to us`)}
            muted
          />
        )}
        <Button
          testID={`${testID}.create`}
          onClick={handleSubmit}
          label={t('CreateButtonLabel', `Create`)}
          rounded={isEnabled('virtual-assistant')}
        />
      </span>
    </div>
  )
}
