import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '@sigatoka/elements'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { broadcastEvent, deleteAnnouncement } from '@ello/business/services'
import { MessageDetails } from '@ello/components/message-details'
import { useViewController } from '@ello/hooks/use-view-controller'
import { announcementSelector } from '@ello/state/announcements'
import { authState } from '@ello/state/authentication'

import styles from './metrics.module.less'

enum NavigationAction {
  MessageDeletedNotification = 'message-deleted-notification'
}

export const MessageMetrics = ({
  testID = 'message-metrics',
  id
}: Partial<Common.Props> & { id?:string}): React.ReactElement | null => {
  const { setState } = useViewController()
  const [loading, setLoading] = useState(false)
  const { state, contents: data } = useRecoilValueLoadable(
    announcementSelector(id)
  )
  const credentials = useRecoilValue(authState)

  const loadingDetails = useMemo(() => state !== 'hasValue', [state])

  const handleDispatch = useCallback(() => {
    if (data?.id && credentials?.accessToken) {
      setLoading(true)
      broadcastEvent(data.id, credentials.accessToken)
        .then((result) => console.log(result))
        .catch((error) => alert('Something went wrong'))
        .finally(() => setLoading(false))
    }
  }, [data?.id])

  const handleDelete = useCallback(() => {
    if (data?.id && credentials?.accessToken) {
      deleteAnnouncement(data.id, credentials.accessToken)
        .then(() =>
          navigate('/', {
            state: {
              action: NavigationAction.MessageDeletedNotification,
              value: `Message deleted`
            }
          })
        )
        .catch(() => alert('Could not delete'))
    }
  }, [data?.id])

  useEffect(() => {
    setState({ title: data?.subject || 'Metrics', transparent: false })
  }, [data?.label])

  return (
    <div data-testid={testID} className={styles.frame}>
      <MessageDetails
        testID={`${testID}.details`}
        id={data?.id ?? id}
        loading={loadingDetails}
        title={data?.subject}
        body={data?.body}
        variants={data?.variants}
        targets={data?.targets}
        subscribers={data?.subscribers}
        broadcasts={data?.broadcasts}
      />

      <Button
        testID={`${testID}.broadcast`}
        icon={'shout'}
        onClick={handleDispatch}
        loading={loading}
        disabled={loadingDetails}
        label={'Broadcast'}
        size={'large'}
      />
    </div>
  )
}
