import React from 'react'
import  { IconButton, Input } from '@sigatoka/elements'
import clsx from 'classnames'

import styles from './SearchInput.module.less'

type Variant =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'muted'
  | 'overlay'
  | 'active'

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'children'>,
    Common.Props {
  variant?: Variant
  label?: string
  loading?: boolean
  rounded?: boolean
  /**
   * Switches the input into a textarea
   */
  multiline?: boolean
  outline?: boolean
  children?:
    | React.ReactElement<AccessoryViewProps>
    | React.ReactElement<AccessoryViewProps>[]
}

export const SearchInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Omit<InputProps, 'type' | 'role' | 'label'>>
>(({ testID, children, ...props }, ref) => (
  <Input
    {...props}
    ref={ref}
    testID={testID}
    type={'text'}
    role={'search'}
    inputMode={'search'}
    autoComplete={'off'}
    spellCheck={false}
    className={clsx(
      props.className,
      styles.search,
      props.outline && styles.outline
    )}
    rounded
  >
    {/* <Accessory.View
        testID={`${testID}.children`}
        position={'left'}
        padding={'100'}
        className={styles.accessories}>
        {children}
      </Accessories.View> */}
    <IconButton
      testID={`${testID}.button`}
      name={props.name}
      aria-label={props['aria-label']}
      icon={'search'} // Lottie animate into a clear (close) icon when input is valid (has value/tags)
      position={'right'}
      variant={'highlight'}
    />
  </Input>
))
