/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { Route as Page, Routes as Switch, useNavigate } from 'react-router'
import { TabControl } from '@sigatoka/elements'

import { MessageMetrics } from '@ello/components/metrics'
import { VariantsScene } from '../variants'
import styles from './AnnouncementScene.module.less'
import { Header } from '@ello/components/header'

export const AnnouncementScene = ({
  testID = 'announcement'
}: Partial<Common.Props>): React.ReactElement => {
  const navigate = useNavigate()

  return (
    <div data-testid={testID} className={styles.frame}>
      <Header testID={`${testID}.header`}>
        <TabControl
          testID={`${testID}.menu`}
          defaultValue={'subscribers'}
          options={[
            { label: 'Subscribers', value: 'subscribers' },
            { label: 'Variants', value: 'variants' },
            { label: 'Triggers', value: 'triggers' }
          ]}
          onChange={navigate}
          rounded
          dense
        />
      </Header>

      <Switch>
        <Page path={'variants'} Component={VariantsScene} />
        <Page index Component={MessageMetrics} />
      </Switch>
    </div>
  )
}
