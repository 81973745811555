import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { createAnnouncement } from '@ello/business/services/create-announcement'
import type { Announcement } from '@ello/interfaces'
import { announcementState,  } from '@ello/state/announcements'
import {  authState } from '@ello/state/authentication'

export const useEvent = (): {
  loading: boolean
  event: Announcement
  update: (details: Partial<Announcement>) => void
  save: (event: Announcement) => Promise<Announcement | null> | void
} => {
  const credentials = useRecoilValue(authState)
  const [event, setEvent] = useRecoilState(announcementState)
  const [loading, setLoading] = useState(false)

  const update = (details: Partial<Announcement>): void =>
    setEvent(
      (prev): Announcement => ({
        ...prev,
        ...details
      })
    )

  const save = (event: Announcement): Promise<Announcement | null> | void => {
    setLoading(true)

    if (credentials) {
      return createAnnouncement(
        event,
        '',//credentials.getAccessToken().getJwtToken()
      ).then((result) => {
        setLoading(false)

        return result
      })
    }
  }

  return {
    loading,
    event,
    update,
    save
  }
}
