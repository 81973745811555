import React from 'react'
import { Route as Page, Routes as Switch, useNavigate } from 'react-router'
import { TabControl } from '@sigatoka/elements'

// import { useAuth } from '@ello/hooks/use-auth'
import { useViewController } from '@ello/hooks/use-view-controller'

import { BillingScreen } from './billing/Billing'
import { DetailsScreen } from './details/DetailsScreen'
import { PreferencesScreen } from './preferences/Preferences'
import { SecurityScreen } from './security/Security'
import styles from './SettingsScene.module.less'
import { Header } from '@ello/components/header'
import { UsageScreen } from './usage/UsageScreen'

const options = [
  { key: 'profile', path: 'profile', label: 'Profile' },
  {
    key: 'payments',
    path: 'payments',
    label: 'Payment settings',
    menu: [
      { key: 'plans', path: 'plans', label: 'Available plans' },
      { key: 'receipts', path: 'receipts', label: 'Receipts' }
    ]
  },
  {
    key: 'preferences',
    path: 'preferences',
    label: 'Preferences',
    menu: [
      {
        key: 'notifications',
        path: 'notifications',
        label: 'Notification settings'
      }
    ]
  },
  {
    key: 'security',
    path: 'security',
    label: 'Security',
    menu: [{ key: 'password', path: 'password', label: 'Change password' }]
  },
  { key: 'keys', path: 'keys', label: 'API Keys' }
]

export const SettingsScene = ({
  testID = 'settings'
}: Partial<Common.Props>): React.ReactElement => {
  useViewController({ title: 'Settings' })
  const navigate = useNavigate()

  // const { signOut } = useAuth()

  const handleTabChange = (value: string) => {
    navigate(value, { replace: true })
  }

  return (
    <div data-testid={testID} className={styles.frame}>
      <Header testID={`${testID}.header`}>
        <TabControl
          testID={`${testID}.menu`}
          name={'menu'}
          defaultValue={'details'}
          options={[
            {
              label: 'Details',
              value: 'details'
            },
            {
              label: 'Usage',
              value: 'usage'
            },
            { label: 'Billing', value: 'billing' },
            { label: 'Preferences', value: 'preferences' },
            { label: 'Security', value: 'security' }
          ]}
          onChange={handleTabChange}
          rounded
          dense
        />
      </Header>
      <Switch>
        <Page path={'details'} Component={DetailsScreen} />
        <Page path={'usage'} Component={UsageScreen} />
        <Page path={'preferences'} Component={PreferencesScreen} />
        <Page path={'billing'} Component={BillingScreen} />
        <Page path={'security'} Component={SecurityScreen} />
        <Page index Component={DetailsScreen} />
      </Switch>
    </div>
  )
}
