import axios from 'axios'

import { ServiceEndpoint } from './service-endpoint'

export const broadcastEvent = (
  eventId: string,
  accessToken: string | null
): Promise<boolean> => {
  if (eventId && accessToken) {
    return axios
      .post(ServiceEndpoint.servicePath(`broadcast/${eventId}`), undefined, {
        headers: {
          Authorization: accessToken
        }
      })
      .then((response) => !!response)
      .catch((error) => {
        console.error(error)

        throw error
      })
  }

  return Promise.resolve(false)
}
