import { Typography } from '@sigatoka/elements'
import { useRecoilValueLoadable } from 'recoil'
import { Card } from '@ello/components/card'

import { announcementSelector } from '@ello/state'

import { MetricsCardProps } from './metrics-card.interface'
import styles from './metrics-card.module.less'

export const MetricsCard = ({
  testID,
  id,
  title,
  subtitle
}: MetricsCardProps): React.ReactElement => {
  const { state, contents: data } = useRecoilValueLoadable(
    announcementSelector(id)
  )

  return (
    <Card testID={testID} padded rounded>
      <Typography testID={`${testID}.title`} variant={'title'} text={title} />
      {subtitle ? (
        <Typography
          testID={`${testID}.subtitle`}
          variant={'subtitle'}
          text={subtitle}
        />
      ) : null}

      <div className={styles.group}>
        <div className={styles.metric}>
          <Typography testID={`${testID}.count`} text={'246'} />
          <Typography testID={`${testID}.label`} text={'Subs'} />
        </div>
        <div className={styles.metric}>
          <Typography testID={`${testID}.count`} text={'7'} />
          <Typography testID={`${testID}.label`} text={'Events'} />
        </div>
        <div className={styles.metric}>
          <Typography testID={`${testID}.count`} text={'18'} />
          <Typography testID={`${testID}.label`} text={'Broadcasts'} />
        </div>
      </div>
    </Card>
  )
}
