import React from 'react'

interface CardProps extends Common.Props {
    padded?: boolean
}

export const Card = ({ testID, children }:React.PropsWithChildren<CardProps>) => {

    return (
        <span data-testid={testID}>{children}</span>
    )
}