import axios from 'axios'

import { Announcement } from '@ello/interfaces'

import { ServiceEndpoint } from './service-endpoint'

type AllAnnouncementsOptions = {
  skip: number
  limit: number
}

const SKIP = 0
const LIMIT = 10

export const allAnnouncements = (
  accessToken?: string | null,
  { skip = SKIP, limit = LIMIT }: Partial<AllAnnouncementsOptions> = {}
): Promise<Announcement[] | null> => {
  if (accessToken) {
    return axios
      .get(ServiceEndpoint.servicePath('announcements'), {
        params: { skip, limit },
        headers: {
          Authorization: accessToken
        }
      })
      .then((response) => response?.data?.events || null)
      .catch((error) => {
        console.error(error)

        return null
      })
  }

  return Promise.resolve(null)
}
