import React from 'react'

import styles from './layout.module.less'
import { FeedScene } from '@ello/scenes'
import { Icon, Typography } from '@sigatoka/elements'
import { NavLink } from 'react-router-dom'
import { PageControl } from '../page-control/PageControl'

const menuItems = [
  {
    key: 'home',
    path: '/',
    icon: 'discover',
    label: 'Discover'
  },
  {
    key: 'compose',
    path: '/compose',
    icon: 'compose',
    label: 'Composer'
  },
  {
    key: 'settings',
    path: '/settings',
    icon: 'settings',
    label: 'Settings'
  }
]

export const Layout = ({
  testID,
  children
}: React.PropsWithChildren<Common.Props>): React.ReactElement => (
  <div data-testid={`${testID}.layout`} className={styles.layout}>
    <div data-testid={`${testID}.menu`} className={styles.menu}>
      <PageControl testID={`${testID}.controls`} options={menuItems} />
      <span className={styles.navigation}>
        <NavLink to={'/me'}>
          <Icon testID={`${testID}.icon`} icon={'user'} />
        </NavLink>
      </span>
    </div>
    <div data-testid={testID} className={styles.frame}>
      <div data-testid={`${testID}.feature`} className={styles.feature}>
        <FeedScene testID={'feed'} />
      </div>
      <div data-testid={`${testID}.main`} className={styles.main}>
        {children}
      </div>
    </div>
    <footer>
      <Typography
        testID={`${testID}.impressum`}
        variant={'caption'}
        text={`Copyright © 2024 - ello`}
      />
    </footer>
  </div>
)
