import styles from './MemberScene.module.less'
import { Typography } from '@sigatoka/elements'
import { useSession } from '@ello/state'
import { NavLink } from 'react-router-dom'

export const MemberScene = ({
  testID = 'member'
}: Partial<Common.Props>): React.ReactElement => {
  const { session } = useSession()

  return (
    <div data-testid={testID} className={styles.frame}>
      <Typography
        variant={'lead'}
        text={`Welcome ${session?.name ?? session?.handle}`}
      />
      <NavLink
        data-testid={`${testID}.create`}
        to={'/compose'}
        className={styles.create}
      >
        <Typography
          testID={`${testID}.label`}
          variant={'link'}
          color={'#fff'}
          text={`Compose`}
        />
      </NavLink>
      <NavLink
        data-testid={`${testID}.create`}
        to={'/settings'}
        className={styles.create}
      >
        <Typography
          testID={`${testID}.label`}
          variant={'link'}
          color={'#fff'}
          text={`Settings`}
        />
      </NavLink>
    </div>
  )
}
