import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

const viewControllerState = atom<{
  title: string | null
  transparent: boolean
}>({
  key: 'view-controller',
  default: {
    title: null,
    transparent: false
  }
})

const useViewController = ({
  title: initialTitle,
  transparent = false
}: { title?: string; transparent?: boolean } = {}) => {
  const [state, setState] = useRecoilState(viewControllerState)

  useEffect(() => {
    initialTitle && setState({ title: initialTitle, transparent })
  }, [initialTitle])

  return {
    title: state.title,
    transparent: state.transparent,
    setState
  }
}

export { useViewController, viewControllerState }
