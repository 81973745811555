import { Input, Typography, Button } from '@sigatoka/elements'

import styles from './DetailsScreen.module.less'
import { useSession } from '@ello/state'

export const DetailsScreen = ({
  testID = 'details'
}: Partial<Common.Props>): React.ReactElement => {
  const { clearSession } = useSession()

  return (
    <div data-testid={testID} className={styles.frame}>
      <Typography
        testID={`${testID}.heading`}
        variant={`heading`}
        text={`Your details`}
      />
      <Input
        testID={`${testID}.email`}
        label={`Email address`}
        defaultValue={`email@example.com`}
      />
      <Input
        testID={`${testID}.mobile`}
        label={`Mobile number`}
        defaultValue={`0431 536 911`}
      />
      <Input
        testID={`${testID}.address`}
        label={`Residential address`}
        defaultValue={`123 Fake Street, Brisbane City 4000 QLD`}
      />
      <Typography testID={`${testID}.close`} text={`Close my account`} />
      <Button
        testID={`${testID}.logout`}
        onClick={clearSession}
        label={`Logout`}
      />
    </div>
  )
}
