import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Spinner, Typography } from '@sigatoka/elements'
import classnames from 'classnames'
import lottie, { AnimationItem } from 'lottie-web-light'

import { useConfig } from '@ello/hooks/use-config'

import { DetailProps } from './detail.interface'
import styles from './detail.module.less'

const ACCESSORY_NAME = 'accessory'


export const Detail = ({
  testID,
  disabled,
  to,
  label,
  icon,
  muted,
  loading
}: DetailProps): React.ReactElement => {
  const { getAssetUrl, getIconUrl } = useConfig()
  const accessoryRef = useRef<HTMLDivElement>(null)
  const accessoryAnimationRef = useRef<AnimationItem>()

  useEffect(() => {
    if (!accessoryAnimationRef.current && accessoryRef.current) {
      accessoryAnimationRef.current = lottie.loadAnimation({
        name: ACCESSORY_NAME,
        path: getAssetUrl('arrow.json'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        container: accessoryRef.current
      })
    }

    return (): void => {
      accessoryAnimationRef.current?.destroy(ACCESSORY_NAME)
    }
  }, [])

  if (to) {
    return (
      <NavLink
        data-testid={testID}
        to={to}
        className={classnames(styles.frame, styles.link)}
      >
        <div data-testid={`${testID}.accessory`} className={styles.accessory}>
          {icon ? (
            <img
              data-testid={`${testID}.icon`}
              alt={'icon'}
              src={getIconUrl(icon)}
            />
          ) : null}
        </div>

        <Typography
          testID={`${testID}.label`}
          text={label}
          weight={'normal'}
          disabled={disabled}
          muted={muted}
        />
      </NavLink>
    )
  }

  return (
    <div data-testid={testID} className={styles.frame}>
      <div data-testid={`${testID}.accessory`} className={styles.accessory}>
        {icon ? (
          <img
            data-testid={`${testID}.icon`}
            alt={'icon'}
            src={getIconUrl(icon)}
            className={styles.accessory}
          />
        ) : null}
      </div>

      <Typography
        testID={`${testID}.label`}
        text={label}
        weight={'normal'}
        disabled={disabled}
        muted={muted}
      />
    </div>
  )
}
