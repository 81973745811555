import { atom, useRecoilState } from 'recoil'
import { Session, useSessionHistory } from './use-session-history'

const ATOM_KEY = 'sessions'

enum Platform {
  Instagram = 'instagram',
  Mobile = 'mobile'
}

const activeSessionAtom = atom<Session | null>({
  key: ATOM_KEY,
  default: null
})

export const useSession = () => {
  const { sessions } = useSessionHistory()
  const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom)

  const addSession = () => {}

  const selectSession = (id: string) => {
    const session = sessions.find((session) => session.id === id)
    if (!session) return
    setActiveSession(session)
  }

  const clearSession = () => {
    setActiveSession(null)
  }

  return {
    session: activeSession,
    history: sessions,
    /**
     * Updates the currently active session
     */
    selectSession,
    /**
     * Performs a logout style operation by
     * clearing the current active session
     */
    clearSession
  }
}
