import { useState } from 'react'
import { Button, Typography, Icon } from '@sigatoka/elements'

import { PaymentMethod, SavedPayment } from '@ello/components/payment-method'

import styles from './Billing.module.less'

type Payment = {
  type: PaymentMethod
  cardNumber: string
  expiryDate: string
  preferred?: boolean
}

export const BillingScreen = ({
  testID = 'billing'
}: Partial<Common.Props>): React.ReactElement => {
  const [paymentMethods, setPaymentMethods] = useState<Payment[]>([
    {
      type: 'card',
      cardNumber: '**** 7648',
      expiryDate: '01/2029',
      preferred: true
    },
    { type: 'apple', cardNumber: 'Apple pay', expiryDate: 'Learn More' }
  ])

  const handleAddPaymentMethod = (): void => {
    setPaymentMethods([
      ...paymentMethods,
      {
        type: 'card',
        cardNumber: '**** 1234',
        expiryDate: '01/2029',
        preferred: false
      }
    ])
  }

  return (
    <div data-testid={testID} className={styles.frame}>
      <div className={styles.section}>
        <span className={styles.heading}>
          <Icon testID={`${testID}.icon`} icon={`credit`} />
          <Typography
            testID={`${testID}.heading`}
            variant={`heading`}
            text={`Credits`}
          />
        </span>

        <Typography
          testID={`${testID}.description`}
          text={`Available credits`}
        />

        <Typography
          testID={`${testID}.credits`}
          variant={'subheading'}
          text={`$4.99`}
        />
        <div className={styles.actions}>
          <Button
            testID={`${testID}.auto`}
            onClick={handleAddPaymentMethod}
            label={`Automatic Top Up`}
            className={styles.button}
            variant={'muted'}
          />
          <Button
            testID={`${testID}.buy`}
            onClick={handleAddPaymentMethod}
            label={`Buy Credits`}
            className={styles.button}
            variant={'muted'}
          />
        </div>
      </div>

      <div className={styles.section}>
        <Typography
          testID={`${testID}.heading`}
          variant={`heading`}
          text={`Billing cycle`}
        />
        <Typography
          testID={`${testID}.description`}
          text={`Choose a preferred billing cycle.`}
        />
        <Button
          testID={`${testID}.logout`}
          onClick={() => alert('billing cycle updated!')}
          label={`Choose a preferred billing cycle`}
          className={styles.button}
        />
      </div>

      <div className={styles.section}>
        <Typography
          testID={`${testID}.heading`}
          variant={`heading`}
          text={`Payment methods`}
        />
        <Typography
          testID={`${testID}.description`}
          text={`Update or delete your saved payment methods.`}
        />
        {paymentMethods.map((method, idx) => (
          <SavedPayment
            key={method.type + idx.toString()}
            testID={`${testID}.card`}
            method={method.type}
            label={method.cardNumber}
            detail={method.expiryDate}
            preferred={method.preferred}
          />
        ))}
        <Button
          testID={`${testID}.logout`}
          onClick={handleAddPaymentMethod}
          label={`Add payment method`}
          className={styles.button}
        />
      </div>
    </div>
  )
}
