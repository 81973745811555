import { Typography } from '@sigatoka/elements'

import styles from './SessionDevice.module.less'

type SessionDetails = {
  device: string
  browser: string
}

type Location = {
  city: string
  region: string
  country: string
}

export type Session = {
  id: string
  details: SessionDetails
  location: Location
  lastActive: Date
}

interface SessionDeviceProps extends Common.Props {
  details: SessionDetails
  location: Location
  lastActive: Date
}

export const SessionDevice = ({
  testID,
  location,
  details,
  lastActive
}: SessionDeviceProps):React.ReactElement => (
  <div className={styles.frame}>
    <div className={styles.header}>
      <Typography
        testID={`${testID}.title`}
        variant={'title'}
        text={`${details.device} • ${details.browser}`}
      />
      <Typography
        testID={`${testID}.action`}
        text={`Logout device`}
        underline
      />
    </div>
    <Typography
      testID={`${testID}.location`}
      text={location.region + ', ' + location.country}
    />
    <Typography
      testID={`${testID}.lastActive`}
      text={lastActive.toDateString()}
    />
  </div>
)
