import { atom, useRecoilValue } from 'recoil'

const ATOM_KEY = 'account'

type AccountPlan = 'pay-as-you-go' | 'monthly'

type AccountUsage = {
  credit: number
}

type Account = {
  usage: AccountUsage
  plan: AccountPlan
}

const accountAtom = atom<Account>({
  key: ATOM_KEY,
  default: {
    usage: {
      credit: 198.49
    },
    plan: 'pay-as-you-go'
  }
})

export const useAccount = () => {
  const accountData = useRecoilValue(accountAtom)

  return {
    account: accountData
  }
}
