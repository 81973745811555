import axios from 'axios'

import { Announcement } from '@ello/interfaces'

import { ServiceEndpoint } from './service-endpoint'

export const getAnnouncement = (
  announcementId: Announcement['id'],
  accessToken?: string | null
): Promise<Announcement | null> => {
  if (announcementId && accessToken) {
    return axios
      .get(ServiceEndpoint.servicePath(`message/${announcementId}`), {
        params: { skip: 0, limit: 10 },
        headers: {
          Authorization: accessToken
        }
      })
      .then((response) => response?.data?.message || null)
      .catch((error) => {
        console.error(error)

        return null
      })
  }

  return Promise.resolve(null)
}
