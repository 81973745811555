/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { Route as Page, Routes as Switch, useParams } from 'react-router'
import { Typography } from '@sigatoka/elements'

import styles from './CreatorScene.module.less'
import { SubscribeScene } from '../subscribe'
import { MetricsCard } from '@ello/components/metrics-card'
import { NavLink } from 'react-router-dom'

export const CreatorScene = ({
  testID = 'creator'
}: Partial<Common.Props>): React.ReactElement => {
  const params = useParams<{ creator: string }>()

  return (
    <div data-testid={testID} className={styles.frame}>
      <Switch>
        <Page path={':announcement'} Component={SubscribeScene} />
        <Page
          index
          Component={() => (
            <div>
              <Typography
                testID={`${testID}.title`}
                variant={'lead'}
                text={params.creator}
              />

              <MetricsCard
                testID={`${testID}.metrics`}
                id={params.creator}
                title={'First Last'}
                subtitle={'@username'}
              />

              <NavLink to={'test'}>Test</NavLink>
            </div>
          )}
        />
      </Switch>
    </div>
  )
}
