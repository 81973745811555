import { LazyImage, Typography } from '@sigatoka/elements'

import styles from './AccountCell.module.less'
import { useCallback, useRef } from 'react'

interface AccountCellProps extends Common.Props {
  title: string
  subtitle: string
  onClick: () => void
  onAction?: (action?: string) => void
}

export const AccountCell = ({
  testID,
  title,
  subtitle,
  onAction,
  onClick
}: AccountCellProps): React.ReactElement => {
  const actionRef = useRef<HTMLSpanElement>(null)
  const handleClick = useCallback(({target}:React.MouseEvent<HTMLElement>) => {
    // Ignore action click in cell selection
    if (onAction && target === actionRef.current) return
    onClick()
  }, [onClick, onAction, actionRef.current])

  const handleAction = useCallback(() => onAction?.(), [onAction])

  return (
    <span className={styles.frame} onClick={handleClick}>
      <LazyImage
        testID={`${testID}.image`}
        src={
          'https://gravatar.com/avatar/ed4197b203d4027ed8722ef5e02aed82?size=256&cache=1711664786631'
        }
        className={styles.image}
      />
      <span className={styles.details}>
        <Typography
          testID={`${testID}.title`}
          text={title}
          variant={'title'}
          weight={'bold'}
        />
        <Typography
          testID={`${testID}.subtitle`}
          variant={'subtitle'}
          text={subtitle}
        />
      </span>
      <span ref={actionRef} className={styles.icon} onClick={handleAction} />
    </span>
  )
}
