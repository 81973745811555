import { OutletLabel } from '@ello/interfaces/outlets'
import { TriggerLabel } from '@ello/interfaces/triggers'

export const STEPS = [
  {
    key: 'DETAILS',
    label: 'Details',
    description: 'What would you like to say?',
    fields: [
      {type: 'text', key: 'subject', label: 'Subject', placeholder: 'Enter a subject'},
    ],
    on: {
      NEXT: 'TARGETS'
    }
  },
  {
    key: 'TARGETS',
    label: 'Targets',
    hint: 'How should subscribers receive your message?',
    description: 'How should subscribers receive your message?',
    options: [
      {
        key: OutletLabel.Email,
        label: 'Email',
        selected: true,
        note: undefined
      },
      {
        key: OutletLabel.SMS,
        label: 'SMS Message',
        selected: false,
        note: `SMS messages incur an additional $0.23 fee`
      },
      {
        key: OutletLabel.Telegram,
        label: 'Telegram',
        selected: true,
        note: undefined
      },
      {
        key: OutletLabel.Messenger,
        label: 'Messenger',
        selected: false,
        note: undefined
      },
      {
        key: OutletLabel.Webhook,
        label: 'WebHooks',
        selected: false,
        note: undefined
      }
    ],
    on: {
      NEXT: 'TRIGGERS'
    }
  },
  {
    key: 'TRIGGERS',
    label: 'Triggers',
    hint: 'When should we notify your subscribers?',
    description: 'When should we notify your subscribers?',
    options: [
      {
        key: TriggerLabel.Manual,
        label: 'Manually',
        selected: false,
        note: undefined
      },
      {
        key: TriggerLabel.Schedule,
        label: 'Scheduled',
        selected: true,
        note: undefined
      },
      {
        key: TriggerLabel.Location,
        label: 'Locations',
        selected: false,
        note: undefined
      },
      {
        key: TriggerLabel.WebHook,
        label: 'WebHooks',
        selected: true,
        note: undefined
      },
      {
        key: TriggerLabel.Event,
        label: 'Event',
        selected: false,
        note: undefined
      }
    ],
    on: {
      NEXT: 'VARIANTS'
    }
  },
  {
    key: 'VARIANTS',
    label: 'Variations',
    description: 'Customize how your message will appear across devices',
    on: {
      NEXT: 'DETAILS'
    },
    variants: [
      {
        key: `cf4159da-c006-426b-acf4-1d249807c802`,
        targets: [OutletLabel.Email],
        body: `Hello {{name}}, this is a test email`
      },
      {
        key: `623f55bc-8239-4193-9cd2-f05a9d77cd80`,
        targets: [OutletLabel.SMS, OutletLabel.Telegram],
        body: `Hello {{name}}, this is a test multi-outlet`
      }
    ]
  }
]
