import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Input, Link, Typography } from '@sigatoka/elements'

import { LoginFormField, LoginFormProps } from './login-form.interface'
import styles from './login-form.module.less'

type AuthAttributes = {
  username: string
  password: string
  confirm: string
  givenName: string
  surname: string
}

const useAuth = () => {
  return {
    loading: false,
    signIn: async (username: string, password: string) => {
      console.log('Sign in', username, password)
    },
    signUp: async (username: string, password: string) => {
      console.log('Sign up', username, password)
    }
  }
}

export const LoginForm = ({
  testID = 'login'
}: LoginFormProps): React.ReactElement => {
  const { pathname } = useLocation()
  const [error, setError] = useState<string | null>(null)
  const [attributes, setAttributes] = useState<Partial<AuthAttributes>>({})
  const { loading, signIn, signUp } = useAuth()

  const isLogin = useMemo(() => pathname.match(/login/gi), [pathname])

  const handleAuthError = useCallback((error: string) => {
    setError(error)
  }, [])

  const didChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setError(null)

      setAttributes((previous) => ({
        ...previous,
        [event.target.name]: event.target.value
      }))
    },
    []
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault()

      if (attributes.username && attributes.password) {
        if (!isLogin) {
          console.log('Signup', attributes)
          signUp(attributes.username, attributes.password).catch(
            handleAuthError
          )
        } else {
          signIn(attributes.username, attributes.password).catch(
            handleAuthError
          )
        }
      }
    },
    [attributes]
  )

  return (
    <div data-testid={testID}>
      <form
        data-testid={`${testID}.form`}
        className={styles.frame}
        onSubmit={handleSubmit}
      >
        <Typography
          testID={'title'}
          text={isLogin ? 'Sign in' : 'Create an account'}
        />

        {error && (
          <Typography
            testID={`${testID}.error`}
            variant={'error'}
            text={error}
          />
        )}

        <Input
          testID={'username'}
          name={LoginFormField.Username}
          defaultValue={attributes.username}
          label={'Username'}
          onChange={didChangeInput}
          required
        />
        <Input
          testID={'password'}
          name={LoginFormField.Password}
          type={'password'}
          defaultValue={attributes.password}
          label={'Password'}
          onChange={didChangeInput}
          required
        />
        {!isLogin && (
          <>
            <Input
              testID={'confirm'}
              name={LoginFormField.Confirm}
              type={'password'}
              defaultValue={attributes.confirm}
              label={'Confirm password'}
              onChange={didChangeInput}
              required
            />
            {/* <Input
              testID={`${testID}.givenName`}
              name={LoginFormField.GivenName}
              defaultValue={attributes.givenName}
              label={'Given name'}
              onChange={didChangeInput}
              required
            />
            <Input
              testID={`${testID}.surname`}
              name={LoginFormField.Surname}
              defaultValue={attributes.surname}
              label={'Surname'}
              onChange={didChangeInput}
              required
            /> */}
          </>
        )}
        <Button
          testID={'submit'}
          type={'submit'}
          text={isLogin ? 'Login' : 'Register'}
          disabled={!!error}
          loading={loading}
        />

        <Link
          testID={`${testID}.link`}
          text={isLogin ? 'Create account' : 'Already have an account?'}
          to={isLogin ? '/register' : '/login'}
        />
      </form>
    </div>
  )
}
